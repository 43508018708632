/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
// import { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "../styles/index.scss"

const Layout = ({ siteTitle, children }) => {
  // const loadJs = (url, name) => {
  //   return new Promise(resolve => {
  //     import(`../js/index`).then(module => {
  //       console.log(module)
  //       console.log(module.default)
  //       resolve(module.default)
  //     })
  //   })
  // }
  // loadJs()

  const data = useStaticQuery(
    graphql`
      query metaData {
        site {
          siteMetadata {
            title
            siteUrl
            description
            author
          }
        }
      }
    `
  )

  // console.log(LoadableBuyButton)
  // import("../js/index")
  //   .then(m => {
  //     console.log(m.default)
  //     m.default()
  //   })
  //   .catch(err => {
  //     console.error("Error during loading module: " + err)
  //   })

  return (
    <>
      {/* <LoadableBuyButton /> */}
      <canvas id="three"></canvas>
      <div className="sticky-scroll">
        <Header
          siteTitle={siteTitle || data.site.siteMetadata?.title || `Title`}
        />
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
