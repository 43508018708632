import * as React from "react"

const Footer = () => (
  <footer className="footer">
    <div className="marquee">
      <span>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/yondr_agency/"
        >
          Instagram
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/theyondr"
        >
          Facebook
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/company/the-yondr/"
        >
          LinkedIn
        </a>
        <span className="repeat">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/yondr_agency/"
          >
            Instagram
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/theyondr"
          >
            Facebook
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/the-yondr/"
          >
            LinkedIn
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/yondr_agency/"
          >
            Instagram
          </a>
        </span>
      </span>
    </div>
    <div className="marquee reversed">
      <span>
        <a target="_blank" rel="noreferrer" href="https://yondr.agency/">
          yondr.agency
        </a>
        <a href="mailto:hi@yondr.agency">Contact us!</a>
        <span className="repeat">
          <a target="_blank" rel="noreferrer" href="https://yondr.agency/">
            yondr.agency
          </a>
          <a href="mailto:hi@yondr.agency">Contact us!</a>
          <a href="#">yondr.agency</a>
          <a href="mailto:hi@yondr.agency">Contact us!</a>
          <a target="_blank" rel="noreferrer" href="https://yondr.agency/">
            yondr.agency
          </a>
          <a href="mailto:hi@yondr.agency">Contact us!</a>
          <a target="_blank" rel="noreferrer" href="https://yondr.agency/">
            yondr.agency
          </a>
        </span>
      </span>
    </div>

    <div className="marquee slower">
      <span>
        <a href="https://yondr.agency/" target="_blank" rel="noreferrer">
          Follow us!
        </a>
        <a href="tel:+32485947456">Call us!</a>
        <a href="tel:+32485947456">Text us!</a>
        <span className="repeat">
          <a href="https://yondr.agency/" target="_blank" rel="noreferrer">
            Follow us!
          </a>
          <a href="tel:+32485947456">Call us!</a>
          <a href="tel:+32485947456">Text us!</a>
          <a href="https://yondr.agency/" target="_blank" rel="noreferrer">
            Follow us!
          </a>
          <a href="tel:+32485947456">Call us!</a>
          <a href="tel:+32485947456">Text us!</a>
        </span>
      </span>
    </div>
    <div className="footer-info">
      <p>Yondr 19 - 22&copy;</p>
      <p>
        <span className="hide-t">Grote Markt 19 — 9120 Beveren </span>
        <a className="footer-info-number" href="tel:+32486475049">
          0032 486 475 049
        </a>
      </p>
      <p>
        <a href="https://yondr.agency/terms-of-use">Terms</a>—
        <a href="https://yondr.agency/terms-of-use">Privacy policy</a>
      </p>
    </div>
  </footer>
)

export default Footer
