/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, keywords, robots, image }) {
  let { allGraphCmsSeo: site } = useStaticQuery(
    graphql`
      query {
        allGraphCmsSeo {
          edges {
            node {
              title
              keywords
              id
              description
              image {
                url
                width
                height
              }
              url
              siteName
            }
          }
        }
      }
    `
  )
  site = site.edges[0].node

  const metaDescription = description || site.description
  const defaultTitle = site.siteTitle

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords || "",
        },
        {
          name: `robots`,
          content: robots || "index,follow",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: site.url,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name`,
          content: site.siteName,
        },

        {
          property: `og:image`,
          content: image ? image.url : site.image.url,
        },
        {
          property: `og:image:secure_url`,
          content: image ? image.url : site.image.url,
        },
        {
          property: `og:image:width`,
          content: image ? image.width : site.image.width,
        },
        {
          property: `og:image:height`,
          content: image ? image.height : site.image.height,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `fb:app_id`,
          content: "597579788171804",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      // script={[
      //   {
      //     type: "text/javascript",
      //     src: "js/index.js",
      //   },
      // ]}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
