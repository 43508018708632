import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Loader from "./loader.js"
import { useStaticQuery, graphql } from "gatsby"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(
    graphql`
      query HeaderQuery {
        graphCmsOrdering {
          order {
            ... on GraphCMS_Page {
              slug
              title
              pageTemplate
            }
          }
        }
      }
    `
  )

  let pathname = ""
  const isBrowser = () => typeof window !== "undefined"
  if (isBrowser()) pathname = window.location.pathname

  const navItems = data.graphCmsOrdering.order

  const _onClick = (e, isCurrent) => {
    if (isCurrent) e.preventDefault()
  }

  return (
    <>
      <Loader />
      <div className="sticky-child">
        <header className="header">
          <div className="header-title">
            <h1>{siteTitle}</h1>
          </div>
          <div className="header-menu">
            <button className="hamburger">
              {" "}
              <span></span> <span></span>
            </button>
            <nav>
              <ul>
                {navItems.map(function (navItem, i) {
                  let url = pathname.includes(navItem.slug)
                    ? "#"
                    : `/${navItem.slug}`
                  if (navItem.pageTemplate === "introduction") url = "/"
                  if (
                    navItem.pageTemplate === "introduction" &&
                    pathname === "/"
                  )
                    url = "#"
                  let disableCurrent = url === "#"

                  return (
                    <li key={i}>
                      <Link
                        onClick={e => _onClick(e, disableCurrent)}
                        className={
                          pathname.includes(`/${navItem.slug}`) ||
                          (navItem.pageTemplate === "introduction" &&
                            pathname === "/")
                            ? "active"
                            : ""
                        }
                        to={url}
                      >
                        {navItem.pageTemplate === "introduction"
                          ? "Introduction"
                          : navItem.pageTemplate === "experts"
                          ? "The Experts"
                          : navItem.title}
                      </Link>
                    </li>
                  )
                })}
                <li></li>
                <li>
                  <a
                    className="player-spotify"
                    target="_blank"
                    rel="noreferrer"
                    href="https://open.spotify.com/episode/0E6Mw7Trfs8lxQoUh5J7YI?si=4cdc0fb4486e4d83"
                  >
                    Listen on Spotify
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
