import * as React from "react"
import { useEffect } from "react"

import Logo from "../images/logo.svg"

function Loader() {
  useEffect(() => {
    const isBrowser = () => typeof window !== "undefined"
    if (!isBrowser()) return
    window.preloadCounter = 0
    document
      .querySelector("#circle")
      .addEventListener("animationiteration", () => {
        //count iterations
        window.preloadCounter++
        // console.log(`Animation iteration count: ${window.preloadCounter}`);
      })

    // no animation if not first time
    let hasPreloadedAlready = sessionStorage.getItem("preloader")
    if (hasPreloadedAlready) {
      document.querySelector(".preloader").style.transition = "none"
      document.querySelector(".preloader").classList.add("move_top")
    }
  }, [])

  return (
    <div className="sticky-logo">
      <div className="preloader">
        <div className="preloader-logo animate">
          <Logo />
        </div>
        <p className="preloader-text">
          Loading<span className="preloader-dots">...</span>
        </p>
      </div>
      <div className="preloader-background"></div>
    </div>
  )
}

export default Loader
